import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { App } from "src/app";
import ErrorBoundary from "./components/error-boundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

///SENTRY
if (process.env.REACT_APP_SNUG_ENV !== "LOCAL") {
  Sentry.init({
    dsn: "https://b16964dbfecd408890661d4d60c1855f@o1300297.ingest.sentry.io/6553471",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: process.env.REACT_APP_SNUG_ENV !== "LOCAL",
    environment: process.env.REACT_APP_SNUG_ENV,
  });
}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
