import type { User } from "src/types/user";
import { getToken, getUser, signUp, refreshToken, updateFirstName } from "src/api/snugtotal/auth";
import toast from "react-hot-toast";

// const STORAGE_KEY: string = "users";

// NOTE: Not currently using the persisting user methodology but should revist in the future.

// const getPersistedUsers = (): User[] => {
//   try {
//     const data = localStorage.getItem(STORAGE_KEY);

//     if (!data) {
//       return [];
//     }

//     return JSON.parse(data) as User[];
//   } catch (err) {
//     console.error(err);
//     return [];
//   }
// };

// const persistUser = (user: User): void => {
//   try {
//     const users = getPersistedUsers();
//     const data = JSON.stringify([...users, user]);
//     localStorage.setItem(STORAGE_KEY, data);
//   } catch (err) {
//     console.error(err);
//   }
// };

type SignInRequest = {
  email: string;
  password: string;
};

type SignInResponse = Promise<{
  accessToken: string;
  refreshToken: string;
}>;

type SignUpRequest = {
  email: string;
  password: string;
  name: string;
};

type SignUpResponse = Promise<{
  user: User;
}>;

type MeRequest = {
  accessToken: string;
};

type RefreshRequest = {
  refresh: string;
};

type MeResponse = Promise<User>;

class AuthApi {
  async signIn(request: SignInRequest): SignInResponse {
    const { email, password } = request;
    return new Promise(async (resolve, reject) => {
      try {
        const data = await getToken(email, password); //calls the token endpoint on authapi
        const accessToken = data.data.access;
        const refreshToken = data.data.refresh;
        resolve({ accessToken, refreshToken });
      } catch (err) {
        console.error(err);
        reject(new Error("Unable to sign in. Please try again."));
      }
    });
  }

  async signUp(request: SignUpRequest): SignUpResponse {
    const { email, password, name } = request;
    return new Promise(async (resolve, reject) => {
      try {
        const data = await signUp(email, password, name);
        // persistUser(user);
        resolve({ user: data});
      } catch (err) {
        if (err.response.status === 409 && err.response.data.code === 1) {
          // reject error user already exists
          reject(new Error("Email already exists"));
        } else if (err.response.status === 409 && err.response.data.code === 2) {
          reject(err)
        }
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async updateFirstName(first_name: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await updateFirstName(first_name);
        resolve(data);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        toast.error("Unable to update name. Please try again.");
        reject(new Error("Internal server error"));
      }
    });
  }


  async refresh(request: RefreshRequest): SignInResponse {
    const { refresh } = request;
    return new Promise(async (resolve, reject) => {
      try {
        const data = await refreshToken(refresh);
        const accessToken = data.data.access;
        const newRefresh = data.data.refresh;
        resolve({ accessToken, refreshToken: newRefresh });
      } catch (err) {
        reject(new Error("Refresh token failed."));
      }
    });
  }

  me(request: MeRequest): MeResponse {
    const { accessToken } = request;

    return new Promise(async (resolve, reject) => {
      try {
        const tokensUser = await getUser(accessToken);
        // Commented out persisted user stuff as we're not worried about this just and can
        // revisit later
        // Merge static users (data file) with persisted users (browser storage)
        // const mergedUsers = [...getPersistedUsers(), tokensUser];

        // const user = mergedUsers.find((user) => user.id === tokensUser.id);
        // if (!user) {
        //   reject(new Error("Invalid authorization token"));
        //   return;
        // }

        resolve({
          id: tokensUser.id,
          username: tokensUser.username,
          groups: tokensUser.groups,
          email: tokensUser.email,
          first_name: tokensUser.first_name,
          email_verification: tokensUser.email_verification,
          plan: tokensUser.plan,
        });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();
