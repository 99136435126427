import type { ReactNode } from "react";
import { useMemo } from "react";
import SvgIcon from "@mui/material/SvgIcon";

import MessageHeartSquareIcon from "src/icons/untitled-ui/duocolor/message-heart-square";
import MedicalCircleIcon from "src/icons/untitled-ui/duocolor/medical-circle";
import BankIcon from "src/icons/untitled-ui/duocolor/bank";
import FilePlus02Icon from "src/icons/untitled-ui/duocolor/file-plus-02";
import Phone01Icon from "src/icons/untitled-ui/duocolor/phone-01";
import FileHeart02Icon from "src/icons/untitled-ui/duocolor/file-heart-02";
import FileShield02Icon from "src/icons/untitled-ui/duocolor/file-shield-02";
import UsersPlusIcon from "src/icons/untitled-ui/duocolor/users-plus";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import Home03Icon from "src/icons/untitled-ui/duocolor/home-03";
import BoxIcon from "src/icons/untitled-ui/duocolor/box";
import { paths } from "src/paths";
import HomeSmile from "src/icons/untitled-ui/duocolor/home-smile";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {

  return useMemo(
    () => {
      return [
        {
          items: [
            {
              title: "Home",
              path: paths.dashboard.index,
              icon: (
                <SvgIcon fontSize="small">
                  <HomeSmile />
                </SvgIcon>
              ),
            },
            {
              title: "Sharing",
              path: paths.dashboard.accountSharing("sharing"),
              icon: (
                <SvgIcon fontSize="small">
                  <UsersPlusIcon />
                </SvgIcon>
              ),
            },
          ],
        },
        {
          subheader: "Essentials",
          items: [
            {
              title: "Wills & Trusts",
              path: paths.dashboard.lwt,
              icon: (
                <SvgIcon fontSize="small">
                  <FileShield02Icon />
                </SvgIcon>
              ),
            },
            {
              title: "Power of Attorney",
              path: paths.dashboard.fpoa,
              icon: (
                <SvgIcon fontSize="small">
                  <FilePlus02Icon />
                </SvgIcon>
              ),
            },
            {
              title: "Health Care Directive",
              path: paths.dashboard.hcd,
              icon: (
                <SvgIcon fontSize="small">
                  <FileHeart02Icon />
                </SvgIcon>
              ),
            },
          ],
        },
        {
          subheader: "Total Planning",
          items: [
            
            {
              title: "Personal",
              path: "/personal",
              icon: (
                <SvgIcon fontSize="small">
                  <Users03Icon />
                </SvgIcon>
              ),
            },
            {
              title: "Legal",
              path: "/legal",
              icon: (
                <SvgIcon fontSize="small">
                  <BoxIcon />
                </SvgIcon>
              ),
            },
            {
              title: "Medical",
              path: "/medical",
              icon: (
                <SvgIcon fontSize="small">
                  <MedicalCircleIcon />
                </SvgIcon>
              ),
            },
            {
              title: "Financial",
              path: "/financial",
              icon: (
                <SvgIcon fontSize="small">
                  <BankIcon />
                </SvgIcon>
              ),
            },
            {
              title: "Property",
              path: "/property",
              icon: (
                <SvgIcon fontSize="small">
                  <Home03Icon />
                </SvgIcon>
              ),
            },
            {
              title: "Digital",
              path: "/digital",
              icon: (
                <SvgIcon fontSize="small">
                  <Phone01Icon />
                </SvgIcon>
              ),
            },
            {
              title: "Legacy",
              path: "/legacy",
              icon: (
                <SvgIcon fontSize="small">
                  <MessageHeartSquareIcon />
                </SvgIcon>
              ),
            },
          ],
        },
      ];
    },
    // [t]
    []
  );
};