import type { FC } from "react";

import { AuthGuard } from "src/guards/auth-guard";
import { OnboardingGuard } from "src/guards/onboarding-guard";

export const withAuthGuard =
  <P extends object>(Component: FC<P>): FC<P> =>
  (props: P) =>
    (
      <AuthGuard>
        <OnboardingGuard>
          <Component {...props} />
        </OnboardingGuard>
      </AuthGuard>
    );
