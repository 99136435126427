import type { FC } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  FullPersonForBuilderForm, UserDataObj
} from "src/types/snugtotal";

import { useEffect, useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import Save01Icon from "@untitled-ui/icons-react/build/esm/Save01";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import get from "lodash/get";

import { FirstDegreePeopleRole } from "src/types/snugtotal";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { SnugPeopleAutocomplete } from "src/pages/components/snug-people-autocomplete";
import {
  buildFullPersonForBuilderForm,
  financialRoleOptions,
  maritalStatusOptions,
  medicalRoleOptions,
  relationshipOptions,
} from "src/utils/snug";
import toast from "react-hot-toast";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useUserDataProvider from "src/contexts/userData-context";

interface AddPlanDialogProps {
  open: boolean;
  ud_id: string | null;
  peopleRoleToEdit: FirstDegreePeopleRole;
  peopleRolesSavingNew: boolean;
  filterdPeopleRolesForDropDown: FirstDegreePeopleRole[];
  allPeopleRoles: FirstDegreePeopleRole[];
  nameLabel: string;
  onCancel: () => void;
  onEditSuccess: (id: string | null) => void;
}

export const AddPlanDialog: FC<AddPlanDialogProps> = (props) => {
  const {
    open,
    ud_id,
    peopleRoleToEdit,
    peopleRolesSavingNew,
    filterdPeopleRolesForDropDown,
    allPeopleRoles,
    nameLabel,
    onCancel,
    onEditSuccess,
  } = props;

  const [invite, setInvite] = useState<boolean>(false);
  const {
    targetUserDatas,
    targetAuthRoles,
    handleSetAuthorizationContext,
    handleAddOrUpdateAuthorizationRoles,
    handleAddOrUpdatePeopleRolesAndUserData,
    clientRole,
  } = useUserDataProvider();
  const targetUserData =
    targetUserDatas?.find(
      (d) => d.ud_id === peopleRoleToEdit.role_target_user_data_ud_id
    ) || null;

  const router = useRouter();

  const eighteenYearsAgo = dayjs().subtract(18, "year").toDate();

  const formik = useFormik<FullPersonForBuilderForm>({
    initialValues: {
      ...buildFullPersonForBuilderForm(
        // peopleRoleToEdit,
        allPeopleRoles,
        targetUserData || null
      ),
      document_role: peopleRoleToEdit || ({} as FirstDegreePeopleRole),
      birthday: targetUserData?.birthday || null,
      submit: null,
    },
    validationSchema: Yup.object({
      role_target_user_data: Yup.object().shape({
        full_name: Yup.string().required("Name is required"),
        phone: Yup.string().nullable(),
        contact_email: Yup.string().email("Invalid email").nullable(),
        birthday: Yup.date()
          .nullable()
          .when("role", {
            is: (val: string) => val === "CHILD",
            then: (schema) =>
              schema
                .required("Birthday is required for children")
                .max(eighteenYearsAgo, "Child must be at least 18 years old"),
          }),
      }),
      relationship_role: Yup.object().shape({
        role: Yup.string().required("Relationship is required"),
        spouse_relationship: Yup.string().when("role", {
          is: (val: string) => val === "SPOUSE",
          then: () => Yup.string().required("Spouse relationship is required"),
        }),
      }),
      document_role: Yup.object().shape({
        person_name_for_role: Yup.string().required("Name is required"),
        gift_category: Yup.string().when("role", {
          is: (val: string) => val === "GIFT_RECIPIENT",
          then: () => Yup.string().required("Gift category is required"),
        }),
        gift_description: Yup.string().when("role", {
          is: (val: string) => val === "GIFT_RECIPIENT",
          then: () => Yup.string().required("Gift description is required"),
        }),
        beneficiary_distribution_percentage: Yup.number().when("role", {
          is: (val: string) => val === "CUSTOM_PLAN_BENEFICIARY",
          then: () =>
            Yup.number()
              .required("Percentage is required")
              .min(0, "Percentage must be greater than 0")
              .max(100, "Percentage must be less than 100"),
        }),
      }),
    }),

    onSubmit: async (values, helpers): Promise<void> => {
      try {
        // Save the relationship role
        const savedRelationshipRole =
          await handleAddOrUpdatePeopleRolesAndUserData(ud_id, {
            ...values.relationship_role,
            spouse_anniversary: values.relationship_role?.spouse_anniversary
              ? dayjs(values.relationship_role?.spouse_anniversary)?.format(
                  "YYYY-MM-DD"
                )
              : null,
            role_target_user_data: {
              ...(values.role_target_user_data || null),
              birthday: values.role_target_user_data?.birthday
                ? dayjs(values.role_target_user_data?.birthday)?.format(
                    "YYYY-MM-DD"
                  )
                : null,
            },
          });

        // Save the document role
        await handleAddOrUpdateAuthorizationRoles(
          {
            ...values.document_role,
            role_target_user_data:
              savedRelationshipRole?.role_target_user_data || null,
            role: "AUTH_COLLABORATOR",
          },
          savedRelationshipRole?.role_target_user_data || null,
          invite
        );

        helpers.setStatus({ success: true });
        toast.success("Plan added successfully");
        setTimeout(async () => {
          onCancel();
          onEditSuccess(null);
          // clear form. manually set values back to empty strings
          helpers.resetForm();
          try {
            await handleSetAuthorizationContext(
              savedRelationshipRole?.role_target_user_data?.ud_id || "",
              targetAuthRoles
            );
            router.push(paths.dashboard.index);
          } catch (error) {
            console.error(error);
          }
        }, 500);
      } catch (error) {
        console.error(error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    validateOnMount: false,
    validateOnChange: false,
  });

  const handleBirthdayChange = useCallback(
    (date: Dayjs | null): void => {
      formik.setFieldValue("role_target_user_data.birthday", date);
    },
    [formik]
  );

  const triggerRoleChangeFromPeopleInput = (target: UserDataObj) => {
    const person = buildFullPersonForBuilderForm(
      // formik.values.document_role,
      allPeopleRoles,
      target
    );
    formik.setFieldValue("role_target_user_data", person.role_target_user_data);
    formik.setFieldValue("relationship_role", person.relationship_role);
    formik.setFieldValue(
      "document_role.person_name_for_role",
      target?.full_name
    );
  };

  useEffect(() => {
    formik.setFieldValue("document_role", peopleRoleToEdit);
    const person = buildFullPersonForBuilderForm(
      allPeopleRoles,
      targetUserData
    );
    formik.setFieldValue("role_target_user_data", person.role_target_user_data);
    formik.setFieldValue("relationship_role", person.relationship_role);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleRoleToEdit, allPeopleRoles, targetUserData]);

  const openNewWindow = () => {
    const professional_dashboard_domain =
      process.env.REACT_APP_SNUG_ENV === "PRODUCTION"
        ? "dashboard.getsnug.com"
        : "dashboard.staging.getsnug.com";
        const link = `https://${professional_dashboard_domain}/account?tab=billing&at=${localStorage.getItem("s_at")}`;

    window.open(link, "_blank");
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth={"sm"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={6}>
              <Stack spacing={3}>
                <Typography variant="h5">Add Family Member</Typography>

                <Typography variant="body2">
                  Whether you need to help plan for a spouse, an elderly parent,
                  or an adult child, Snug makes it easy to create plans for each
                  member of your family and manage permissions for sharing and
                  collaboration
                </Typography>

                <Stack spacing={3}>
                  <FormControl
                    error={Boolean(
                      formik.errors.document_role?.person_name_for_role
                    )}
                  >
                    <SnugPeopleAutocomplete
                      formik={formik}
                      peopleRolesSavingNew={peopleRolesSavingNew}
                      targetUserDatas={targetUserDatas}
                      peopleRoles={filterdPeopleRolesForDropDown}
                      nameLabel={nameLabel}
                      triggerRoleChangeFromPeopleInput={
                        triggerRoleChangeFromPeopleInput
                      }
                    />
                    {formik.touched.document_role?.person_name_for_role &&
                      get(
                        formik.errors,
                        "document_role?.person_name_for_role"
                      ) && (
                        <FormHelperText>
                          {get(formik.touched, "role_target_user_data") &&
                            get(
                              formik.errors,
                              "document_role?.person_name_for_role"
                            )}
                        </FormHelperText>
                      )}
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Relationship"
                    name="relationship_role.role"
                    select
                    required
                    SelectProps={{ native: true }}
                    value={formik.values.relationship_role.role || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={
                      formik.values.relationship_role.id
                        ? true
                        : false || peopleRolesSavingNew
                    }
                    InputLabelProps={{
                      shrink: Boolean(formik.values.relationship_role.role),
                    }}
                    error={Boolean(
                      formik.errors.relationship_role?.role &&
                        formik.touched.relationship_role?.role
                    )}
                    helperText={
                      formik.errors.relationship_role?.role &&
                      formik.touched.relationship_role?.role
                    }
                  >
                    <option key={"null"} value={undefined}>
                      {""}
                    </option>
                    {relationshipOptions
                      .filter(
                        (o) =>
                          !["PET", "CHARITY"].includes(o.value) &&
                          !(
                            o.value === "SPOUSE" &&
                            allPeopleRoles.find(
                              (role) => role.role === "SPOUSE"
                            ) &&
                            !formik.values.relationship_role.id
                          )
                      )
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    <option disabled style={{ fontStyle: "italic" }}>
                      -- Professionals --
                    </option>

                    {financialRoleOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    <option disabled style={{ fontStyle: "italic" }}>
                      -- Professionals --
                    </option>
                    {medicalRoleOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                  {formik.values.relationship_role.role === "SPOUSE" && (
                    <TextField
                      fullWidth
                      label="Marital Status"
                      name="relationship_role.spouse_relationship"
                      select
                      required
                      SelectProps={{ native: true }}
                      disabled={
                        peopleRolesSavingNew ||
                        (formik.values.relationship_role?.id ? true : false)
                      }
                      value={
                        formik.values.relationship_role.spouse_relationship
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.errors.relationship_role?.spouse_relationship &&
                          formik.touched.relationship_role?.spouse_relationship
                      )}
                      helperText={
                        formik.errors.relationship_role?.spouse_relationship &&
                        formik.touched.relationship_role?.spouse_relationship
                      }
                    >
                      {maritalStatusOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  )}
                  {formik.values.relationship_role.role === "CHILD" &&
                    !formik.values.relationship_role.id && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl
                          error={
                            get(formik.touched, "role_target_user_data") &&
                            Boolean(
                              get(formik.errors, "relationship_role.birthday")
                            )
                          }
                        >
                          <DatePicker
                            format="MM/DD/YYYY"
                            label="Child's Birthday *"
                            disabled={peopleRolesSavingNew}
                            onChange={handleBirthdayChange}
                            onClose={() =>
                              formik.setFieldTouched(
                                "relationship_role.birthday"
                              )
                            }
                            value={dayjs(
                              formik.values.role_target_user_data?.birthday
                            )}
                          />
                          <FormHelperText>
                            {get(formik.touched, "role_target_user_data") &&
                              get(formik.errors, "relationship_role.birthday")}
                          </FormHelperText>
                        </FormControl>
                      </LocalizationProvider>
                    )}
                  <Stack direction="column" spacing={1}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={invite}
                        name="invite"
                        onChange={() => setInvite(!invite)}
                      />
                      <Typography color="text.secondary" variant="body2">
                        I want to send an email invite to this person
                      </Typography>
                    </Box>
                    {invite && (
                      <TextField
                        fullWidth
                        label="Email"
                        name="role_target_user_data.contact_email"
                        value={
                          formik.values.role_target_user_data.contact_email
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={peopleRolesSavingNew || formik.isSubmitting}
                        error={Boolean(
                          formik.errors.role_target_user_data?.contact_email &&
                            formik.touched.role_target_user_data?.contact_email
                        )}
                        helperText={
                          formik.errors.role_target_user_data?.contact_email &&
                          formik.touched.role_target_user_data?.contact_email
                        }
                      />
                    )}
                  </Stack>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  flexWrap="wrap"
                  spacing={2}
                  justifyContent="space-between"
                >
                  {clientRole?.is_demo_client ?
                  <>
                  <Stack
                    alignItems="center"
                    direction="row"
                    flexWrap="wrap"
                    spacing={2}
                  >
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      onClick={() => {openNewWindow()}}
                      loading={formik.isSubmitting || peopleRolesSavingNew}
                      startIcon={<AutoAwesomeIcon fontSize="small" />}
                    >
                      Demo Account - Upgrade to add plans
                    </LoadingButton>
                    <Button
                      color="inherit"
                      disabled={formik.isSubmitting || peopleRolesSavingNew}
                      onClick={onCancel}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Stack>
                  </>
                  :
                  <>
                  <Stack
                    alignItems="center"
                    direction="row"
                    flexWrap="wrap"
                    spacing={2}
                  >
                    <LoadingButton
                      color={formik.status?.success ? "success" : "primary"}
                      type="submit"
                      variant="contained"
                      loading={formik.isSubmitting || peopleRolesSavingNew}
                      startIcon={
                        <SvgIcon>
                          <Save01Icon />
                        </SvgIcon>
                      }
                    >
                      {formik.status?.success ? (
                        "Success!"
                      ) : (
                        <>Save and Add Plan</>
                      )}
                    </LoadingButton>
                    <Button
                      color="inherit"
                      disabled={formik.isSubmitting || peopleRolesSavingNew}
                      onClick={onCancel}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Stack>
                  </>
                  }
                  
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

AddPlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  ud_id: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  handleAddOrUpdatePeopleRoles: PropTypes.func.isRequired,
  // @ts-ignore
  peopleRoleToEdit: PropTypes.object.isRequired,
  onEditSuccess: PropTypes.func.isRequired,
  peopleRolesSavingNew: PropTypes.bool.isRequired,
  filterdPeopleRolesForDropDown: PropTypes.array.isRequired,
  allPeopleRoles: PropTypes.array.isRequired,
  beneficiaries_id: PropTypes.string,
  // @ts-ignore
  docRole: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  estateDocumentId: PropTypes.string,
};
