import type { FC } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import Avatar from "@mui/material/Avatar";

import { ProfessionalClientRolePeopleRole } from "src/types/snugtotal";

interface TopWhitelabelProps {
  onMobileNavOpen?: () => void;
  clientRole: ProfessionalClientRolePeopleRole | null;
  isPro: boolean;
}

export const TopWhitelabel: FC<TopWhitelabelProps> = (props) => {
  const { clientRole, isPro } = props;

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "fixed",

          top: 0,
          width: {
            md: `100%`,
          },
          zIndex: 9990,
        }}
      >
        {isPro && (
          <Box
            sx={{
              backgroundColor: "#111822",
              py: 0.7,
              color: "primary.contrastText",
            }}
          >
            <Container maxWidth={"lg"}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                textAlign={"left"}
                justifyContent={"center"}
              >
                {clientRole?.user_data?.professional_image && (
                  <Avatar
                    sx={{
                      height: 34,
                      width: 34,
                      border: "2px solid white",
                    }}
                    src={clientRole?.user_data?.professional_image}
                  />
                )}
                {clientRole?.user_data?.professional_group_role_user_data?.professional_group.name ? (
                  <Typography variant="button" sx={{ textTransform: "none" }}>
                    {`Membership and discounts provided by ${clientRole?.user_data?.professional_group_role_user_data?.professional_group.name}`}
                  </Typography>
                ) : clientRole?.user_data?.full_name ? (
                  <>
                    <Typography variant="button" sx={{ textTransform: "none" }}>
                      {`Membership and discounts provided by ${clientRole.user_data?.full_name}`}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
                <Button
                  endIcon={<EastOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
                />
              </Stack>
            </Container>
          </Box>
        )}
      </Box>
    </>
  );
};

TopWhitelabel.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
