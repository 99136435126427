import type { FC } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import { LoadingButton } from "@mui/lab";

interface ConfirmAccessDialogProps {
  open: boolean;
  onClose: () => void;
  clientRole: any;
  clientRoleLoading: boolean;
  handleAcceptDomainLeadRequest: (
    id: string,
    status: "ACCEPTED" | "NEW"
  ) => void;
}

export const ConfirmAccessDialog: FC<ConfirmAccessDialogProps> = (props) => {
  const {
    open,
    onClose,
    clientRole,
    clientRoleLoading,
    handleAcceptDomainLeadRequest,
  } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 4 }}>
          <Stack spacing={6}>
            <Stack spacing={3}>
              <Typography variant="h5">Confirm sharing request</Typography>

              <Typography variant="body2">
                {`${clientRole?.user_data?.full_name} has requested collaborator access to your account. This
                will allow them to view your documents and help you enter
                information on the account.`}
              </Typography>
              <Stack
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                spacing={2}
                justifyContent="space-between"
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  flexWrap="wrap"
                  spacing={2}
                >
                  <LoadingButton
                    variant="contained"
                    size="large"
                    disabled={clientRoleLoading}
                    startIcon={<PersonAddAlt1OutlinedIcon />}
                    onClick={async () => {
                      try {
                        await handleAcceptDomainLeadRequest(
                          clientRole?.id || "",
                          "ACCEPTED"
                        );
                      } catch (error) {
                        console.error(error);
                      } finally {
                        onClose();
                      }
                    }}
                  >
                    Confirm and grant acesss
                  </LoadingButton>
                  <LoadingButton
                    color="inherit"
                    disabled={clientRoleLoading}
                    onClick={async () => {
                      try {
                        await handleAcceptDomainLeadRequest(
                          clientRole?.id || "",
                          "NEW"
                        );
                      } catch (error) {
                        console.error(error);
                      } finally {
                        onClose();
                      }
                    }}
                  >
                    Deny access
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

ConfirmAccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
