import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { Seo } from "src/components/seo";
import { paths } from "src/paths";
import { useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import { resetPassword } from "src/api/snugtotal/auth";
import { useRouter } from "src/hooks/use-router";

interface Values {
  password: string;
}

const initialValues: Values = {
  password: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .max(100, "Must be less than 100 characters")
    .required("Required"),
});

const Page = () => {
  const router = useRouter();
  let { token } = useParams();
  const [buttonText, setButtonText] = useState("Continue");
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await resetPassword(token || "", values.password);
        toast.success("Password reset successfully. Redirecting to login...");
        setButtonText("Automatically redirecting");
        setTimeout(() => {
          router.push(paths.auth.jwt.entry);
        }, 2000);
      } catch (error) {
        setButtonText("Continue");
        toast.error("Error resetting password. Please try again.");
        helpers.setSubmitting(false);
        helpers.setErrors(error.response.data);
      }
    },
  });

  return (
    <>
      <Seo title="Reset Password" />
      <div>
      <Card variant='outlined' sx={{borderRadius:1, p:3, px:4, pb:4}}>
          <CardHeader
            sx={{ pb: 0 }}
            title={<Typography variant="h5">Reset your password</Typography>}

          />
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                sx={{ mt: 4 }}
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                {buttonText}
              </LoadingButton>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Page;
