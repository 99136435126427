import { type FC, type ReactNode } from "react";
import PropTypes from "prop-types";

import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";

import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { UserDataProvider } from "src/contexts/userData-context";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();

  if (settings.layout === "horizontal") {
    return (
      <UserDataProvider>
        <HorizontalLayout
          sections={sections}
          navColor={settings.navColor}
          {...props}
        />
      </UserDataProvider>
    );
  }

  return (
    <UserDataProvider>
      <VerticalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    </UserDataProvider>
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
