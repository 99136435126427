import type { FC } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";


interface TopMarbleProps {
  onMobileNavOpen?: () => void;
}

export const TopMarble: FC<TopMarbleProps> = (props) => {

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "fixed",

          top: 0,
          width: {
            md: `100%`,
          },
          zIndex: 9990,
        }}
      >

          <Box
            sx={{
              backgroundColor: "#111822",
              py: 0.7,
              color: "primary.contrastText",
            }}
          >
            <Container maxWidth={"lg"}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                textAlign={"left"}
                justifyContent={"center"}
              >

                  <Avatar
                    sx={{
                      height: 34,
                      width: 34,
                      border: "2px solid white",
                    }}
                    src={'https://s3.us-east-2.amazonaws.com/assets.getsnug.com/files/marble_logo.png'}
                  />

                  <Typography variant="button" sx={{ textTransform: "none" }}>
                    {`Welcome, friends of Marble Law! Enjoy your exclusive 25% off Wills & Trusts.`}
                  </Typography>
                
                
              </Stack>
            </Container>
          </Box>

      </Box>
    </>
  );
};

TopMarble.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
