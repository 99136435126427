export const paths = {
  index: "/",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
    auth0: {
      callback: "/auth/auth0/callback",
      login: "/auth/auth0/login",
    },
    jwt: {
      // login: '/auth/login',
      // register: '/auth/register'
      entry: "/auth/entry",
      entryStart: "/auth/entry/start",
    },
    firebase: {
      login: "/auth/firebase/login",
      register: "/auth/firebase/register",
    },
    amplify: {
      confirmRegister: "/auth/amplify/confirm-register",
      forgotPassword: "/auth/amplify/forgot-password",
      login: "/auth/amplify/login",
      register: "/auth/amplify/register",
      resetPassword: "/auth/amplify/reset-password",
    },
  },
  dashboard: {
    index: "/",
    personal: "/personal",
    medical: "/medical",
    legacy: "/legacy",
    legal: "/legal",
    financial: "/financial",
    taxes: "/taxes",
    digital: "/digital",
    insurance: "/insurance",
    property: "/property",
    lwt: "/wills-and-trusts",
    fpoa: "/financial-power-of-attorney",
    hcd: "/health-care-directive",
    account: "/account",
    mybenefits: "/benefits",
    accountSharing: (tab: string) => `/account/${tab}`,
    createWillOrTrust: (estateDocId: string, currentTrustName: string | null) =>
      currentTrustName
        ? `/wills-and-trusts/create/${estateDocId}?current_trust_name=${currentTrustName}`
        : `/wills-and-trusts/create/${estateDocId}`,
    createHealthCareDirective: (estateDocId: string) =>
      `/health-care-directive/create/${estateDocId}`,
    createFinancialPowerOfAttorney: (estateDocId: string) =>
      `/financial-power-of-attorney/create/${estateDocId}`,
    customers: {
      index: "/dashboard/customers",
      details: "/dashboard/customers/:customerId",
      edit: "/dashboard/customers/:customerId/edit",
    },
  },
  resetPassword: "/reset-password/:token",
  forgotPassword: "/forgot-password?email=:email",
  notAuthorized: "/401",
  notFound: "/404",
  serverError: "/500",
};
