import type { RouteObject } from 'react-router';
import { Layout as AuthClassicLayout } from 'src/layouts/auth/classic-layout';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import ContactPage from 'src/pages/contact';
import CheckoutPage from 'src/pages/checkout';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { Suspense } from 'react';
import ForgotPassword from 'src/pages/auth/jwt/forgot-password';
import ResetPassword from 'src/pages/auth/jwt/reset-password';

export const routes: RouteObject[] = [
  ...authRoutes,
  ...dashboardRoutes,
  {
    path: 'forgot-password',
    element: <AuthClassicLayout>
                <Suspense>
                  <ForgotPassword />
                </Suspense>
              </AuthClassicLayout>
  },
  {
    path: 'reset-password/:token',
    element: <AuthClassicLayout>
                <Suspense>
                  <ResetPassword />
                </Suspense>
              </AuthClassicLayout>
  },
  {
    path: 'checkout',
    element: <CheckoutPage />
  },
  {
    path: 'contact',
    element: <ContactPage />
  },
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
