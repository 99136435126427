import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useV3DeleteById,
  useV3GenericGetData,
} from "src/api/snugtotal/estate_app";
import { GenericPath } from "src/api/snugtotal/estate_app";

import { useMounted } from "src/hooks/use-mounted";
import { VideoAskRecording } from "src/types/snugtotal";

interface VideoAskRecordingsStoreState {
  recording: VideoAskRecording | null;
  recordings: VideoAskRecording[];
  loading: boolean;
  error: string | null;
}

export const useVideoAskRecordings = (ud_id: string | null) => {
  
  const isMounted = useMounted();
  const [state, setState] = useState<VideoAskRecordingsStoreState>({
    recording: null,
    recordings: [],
    loading: false,
    error: null,
  });

  const deleteVideoAskById = useV3DeleteById(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const fetchVideoAsks = useV3GenericGetData<VideoAskRecording[]>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );

  // refactor the delete videoask to follow the pattern of the handleDeleteStorage
  const handleDeleteStorage = useCallback(
    async (id: string): Promise<void> => {
      if (!ud_id) {
        return Promise.reject("Missing ud_id");
      }

      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        if (!ud_id) {
          return Promise.reject("No ud_id provided");
        }
        if (!id) {
          return Promise.reject("No id provided");
        }
        await deleteVideoAskById({
          ud_id: ud_id,
          entity: "videoask-recordings",
          entity_id: id || undefined,
        });
        if (isMounted()) {
          setState((prevState) => ({
            ...prevState,
            recordings: prevState.recordings.filter((r) => r.id !== id),
            loading: false,
          }));
        }
        toast.success("Recording deleted.");
      } catch (error) {
        toast.error("Failed to delete recording");
        console.error(error);
      }
    },
    [deleteVideoAskById, ud_id, isMounted]
  );

  const fetchRecordings = useCallback(
    async (ud_id: string) => {
      if (!ud_id) {
        return;
      }

      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        const data = await fetchVideoAsks({
          ud_id: ud_id, //bleh. REFACTOR IN v3
          entity: "videoask-recordings",
        });

        if (isMounted()) {
          setState((prevState) => ({
            ...prevState,
            recordings: data,
            loading: false,
          }));
        }
      } catch (err) {
        console.error(err);
        if (isMounted()) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            error: "Error fetching recordings.",
          }));
        }
      }
    },
    [isMounted, fetchVideoAsks]
  );

  useEffect(() => {
    if (ud_id) {
      fetchRecordings(ud_id);
    }
  }, [fetchRecordings, ud_id]);

  return {
    ...state,
    deleteRecording: handleDeleteStorage,
    fetchRecordings,
  };
};
