import { FC, ReactNode, useState } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { Theme } from "@mui/material/styles/createTheme";
import { styled } from "@mui/material/styles";
import { useDialog } from "src/hooks/use-dialog";

import type { NavColor } from "src/types/settings";

import type { Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { TopWhitelabel } from "./top-whitelabel";
import { TopMarble } from "./top-marble";
import { TopReadonly } from "./top-readonly";
import { TopAdmin } from "./top-admin";
import { TopDemo } from "./top-demo";

import useUserDataProvider from "src/contexts/userData-context";

import {
  filterRolesForPermissionPopup
} from "src/utils/snug";
import { AddPlanDialog } from "src/sections/dashboard/add-plan-dialog";
import { VerifyUserDialog } from "src/sections/dashboard/verify-user-dialog";

const SIDE_NAV_WIDTH: number = 280;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("md")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const mobileNav = useMobileNav();
  const addPlanDialog = useDialog();
  const verifyUserDialog = useDialog();
  const {
    userData,
    peopleRoles,
    assumedAccountRole,
    peopleRolesSavingNew,
    targetUserDatas,
    targetAuthRoles,
    clientRole,
    isPro,
    handleInviteFromVerifyDialog,
  } = useUserDataProvider();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const onEditSuccess = (id: string | null) => {
    setSelectedId(id);
    addPlanDialog.handleClose();
    verifyUserDialog.handleClose();
  };

  const handleOpenVerifyDialog = (id: string) => {
    verifyUserDialog.handleOpen();
    setSelectedId(id);
  };

  const cancelAddPlanDialog = () => {
    addPlanDialog.handleClose();
    setSelectedId(null);
  };

  const cancelVerifyDialog = () => {
    verifyUserDialog.handleClose();
    setSelectedId(null);
  };

  return (
    <>
      {assumedAccountRole === "AUTH_READONLY" && (
        <TopReadonly
          onMobileNavOpen={mobileNav.handleOpen}
          userData={userData}
        />
      )}
      {assumedAccountRole === "AUTH_COLLABORATOR" && (
        <TopAdmin
          onMobileNavOpen={mobileNav.handleOpen}
          admin={userData?.full_name || ""}
        />
      )}

      {!assumedAccountRole && (
        <>
          {lgUp &&
            isPro &&
            userData?.total_payment_tier?.total_payment_tier !== "MARBLE" && (
              <TopWhitelabel
                onMobileNavOpen={mobileNav.handleOpen}
                clientRole={clientRole || null}
                isPro={isPro}
              />
            )}
          {lgUp &&
            userData?.total_payment_tier?.total_payment_tier === "MARBLE" && (
              <TopMarble onMobileNavOpen={mobileNav.handleOpen} />
            )}
        </>
      )}
      {clientRole?.is_demo_client &&
      <TopDemo onMobileNavOpen={mobileNav.handleOpen} />
      }
      <TopNav
        onMobileNavOpen={mobileNav.handleOpen}
        assumedAccountRole={assumedAccountRole}
        admin={assumedAccountRole === "AUTH_COLLABORATOR"}
        isPro={isPro}
        isMarble={
          userData?.total_payment_tier?.total_payment_tier === "MARBLE"
            ? true
            : false
        }
      />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          isPro={isPro}
          
          assumedAccountRole={assumedAccountRole}
          admin={assumedAccountRole === "AUTH_COLLABORATOR"}
          isMarble={
            userData?.total_payment_tier?.total_payment_tier === "MARBLE"
              ? true
              : false
          }
          onAddPlanClick={addPlanDialog.handleOpen}
          onVerifyUserClick={handleOpenVerifyDialog}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
          
          onAddPlanClick={addPlanDialog.handleOpen}
          onVerifyUserClick={handleOpenVerifyDialog}
        />
      )}
      <VerticalLayoutRoot
        sx={{
          paddingTop:
            (assumedAccountRole === "AUTH_READONLY" ||
              assumedAccountRole === "AUTH_COLLABORATOR" ||
              isPro ||
              userData?.total_payment_tier?.total_payment_tier === "MARBLE") &&
            lgUp
              ? "48px"
              : "0px",
        }}
      >
        <VerticalLayoutContainer>
          {children}
          <AddPlanDialog
            ud_id={userData?.ud_id || null}
            open={addPlanDialog.open}
            peopleRoleToEdit={{
              id: null,
              role: null,
              estate_document_id: null,
              role_target_user_data: null,
            }}
            peopleRolesSavingNew={peopleRolesSavingNew}

            allPeopleRoles={peopleRoles}
            nameLabel="Name"
            onCancel={cancelAddPlanDialog}
            onEditSuccess={onEditSuccess}
            // filter out people with relationship of pet or charity and also all people who already exist on the list of executors
            filterdPeopleRolesForDropDown={filterRolesForPermissionPopup(
              peopleRoles,
              null,
              ["AUTH_READONLY", "AUTH_COLLABORATOR"],
              targetUserDatas
            )}
          />
          <VerifyUserDialog
            onCancel={cancelVerifyDialog}
            open={verifyUserDialog.open}
            onEditSuccess={onEditSuccess}
            userDataToInvite={
              targetAuthRoles.find(
                (r) =>
                  r.user_data?.ud_id === selectedId
              )?.user_data || {
                ud_id: null,
                contact_email: "",
              }
            }
            peopleRolesSavingNew={peopleRolesSavingNew}
            // filter out people with relationship of pet or charity and also all people who already exist on the list of executors
            handleInviteFromVerifyDialog={
              handleInviteFromVerifyDialog
            }
          />
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
