import type { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { UserDataObj } from "src/types/snugtotal";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";



interface VerifyUserDialogProps {
  open: boolean;
  userDataToInvite: UserDataObj;
  peopleRolesSavingNew: boolean;
  onCancel: () => void;
  onEditSuccess: (id: string | null) => void;
  handleInviteFromVerifyDialog: (targetUserData: UserDataObj) => void;
}

export const VerifyUserDialog: FC<VerifyUserDialogProps> = (props) => {
  const {
    open,
    userDataToInvite,
    peopleRolesSavingNew,
    onCancel,
    onEditSuccess,
    handleInviteFromVerifyDialog
  } = props;



  const formik = useFormik<{ contact_email: string; submit: any }>({
    initialValues: {
      contact_email: "",
      submit: null,
    },
    validationSchema: Yup.object({
      contact_email: Yup.string().email("Invalid email").nullable(),
    }),

    onSubmit: async (values, helpers): Promise<void> => {
      try {
        // Save the relationship role
        await handleInviteFromVerifyDialog({...userDataToInvite, contact_email: values.contact_email});

        helpers.setSubmitting(false);
        helpers.setStatus({ success: true });
        setTimeout(() => {
          onCancel();
          onEditSuccess(null);
          formik.setFieldValue("contact_email", "");
          helpers.resetForm();
        }, 1000);
      } catch (error) {
        console.error(error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
    validateOnMount: false,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setFieldValue("contact_email", userDataToInvite?.contact_email || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataToInvite]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.setFieldValue("contact_email", "");
        onCancel();
      }}
      fullWidth
      maxWidth={"sm"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={6}>
              <Stack spacing={3}>
                <Typography variant="h5">Invite Owner</Typography>
                <Typography variant="body2">
                  Send an email invitation to the owner of this plan. You will
                  continue to have collaborator access to their plan. When they
                  accept the invitation, they will be able to add or update any
                  permissions on their account.
                </Typography>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="contact_email"
                    value={formik.values.contact_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={formik.isSubmitting || peopleRolesSavingNew || formik.status?.success}
                    error={Boolean(
                      formik.errors.contact_email &&
                        formik.touched?.contact_email
                    )}
                    helperText={
                      formik.errors?.contact_email &&
                      formik.touched?.contact_email
                    }
                  />
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  flexWrap="wrap"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    flexWrap="wrap"
                    spacing={2}
                  >
                    <LoadingButton
                      color={formik.status?.success ? "success" : "primary"}
                      type="submit"
                      variant="contained"
                      loading={formik.isSubmitting || peopleRolesSavingNew}
                      startIcon={
                        <SvgIcon>
                          <EmailOutlinedIcon />
                        </SvgIcon>
                      }
                    >
                      {formik.status?.success ? "Success!" : "Send invitation"}
                    </LoadingButton>
                    <Button
                      color="inherit"
                      disabled={formik.isSubmitting || peopleRolesSavingNew}
                      onClick={onCancel}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};