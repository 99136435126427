import { Autocomplete, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FirstDegreePeopleRole, UserDataObj } from "src/types/snugtotal";
import { peopleRoleRelationshipMap } from "src/utils/snug";

interface SnugPeopleAutocompleteProps {
  formik: any;
  peopleRolesSavingNew: boolean;
  targetUserDatas: UserDataObj[];
  peopleRoles: FirstDegreePeopleRole[];
  nameLabel: string;
  triggerRoleChangeFromPeopleInput: (target: UserDataObj) => void;
}

export const SnugPeopleAutocomplete: FC<SnugPeopleAutocompleteProps> = (
  props
) => {
  const {
    formik,
    peopleRolesSavingNew,
    targetUserDatas,
    peopleRoles,
    nameLabel,
    triggerRoleChangeFromPeopleInput,
  } = props;
  const [peopleSelecton, setPeopleSelection] = useState<UserDataObj[]>([]);

  // in a useeffect, loop through each people role, if the people role has a role_target_user_data_ud_id that matches an id in targetUserDatas, add it to the peopleSelection array - unless that user is already in the peopleSelection array then skip that role addition.
  useEffect(() => {
    const peopleSelection: UserDataObj[] = [];
    peopleRoles.forEach((role) => {
      const targetUserData = targetUserDatas.find(
        (tud) => tud.ud_id === role.role_target_user_data_ud_id
      );
      if (targetUserData) {
        if (!peopleSelection.find((ps) => ps.ud_id === targetUserData.ud_id)) {
          peopleSelection.push(targetUserData);
        }
      }
    });
    setPeopleSelection(peopleSelection);
  }, [peopleRoles, targetUserDatas]);

  return (
    <Autocomplete
      value={formik.values.role_target_user_data}
      disabled={peopleRolesSavingNew}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          formik.setFieldValue("role_target_user_data.full_name", newValue);
        } else if (newValue && newValue.full_name) {
          triggerRoleChangeFromPeopleInput(newValue as UserDataObj);
        } else {
          triggerRoleChangeFromPeopleInput(newValue as UserDataObj);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (event) {
          triggerRoleChangeFromPeopleInput({
            ud_id: null,
            full_name: newInputValue,
          });
        }
      }}
      selectOnFocus
      handleHomeEndKeys
      id="professional-free-solo"
      options={peopleSelecton}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        return option.full_name || "";
      }}
      renderOption={(props, option) => (
        <li key={option.ud_id} {...props}>
          {`${option.full_name} 
          (${
            peopleRoleRelationshipMap[
              peopleRoles.find(
                (pr) => pr.role_target_user_data_ud_id === option.ud_id
              )?.role || "OTHER"
            ]
          })
          `}
        </li>
      )}
      freeSolo
      autoFocus
      fullWidth
      renderInput={(params) => (
        <TextField key={params.id} {...params} label={nameLabel} required />
      )}
    />
  );
};
