import { useNavigate } from "react-router-dom";
import { FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import AlertCircleIcon from "@untitled-ui/icons-react/build/esm/AlertCircle";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import useUserDataProvider from "src/contexts/userData-context";
import { QuillEditor } from "src/components/quill-editor";
import { Scrollbar } from "src/components/scrollbar";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import { OtherUserDataObj } from "src/types/snugtotal";
import CardActions from "@mui/material/CardActions";
import { VideoDropDownMenu } from "src/components/video-dropdown-menu";
import { usePopover } from "src/hooks/use-popover";
import { useDialog } from "src/hooks/use-dialog";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MessageHeartSquareIcon from "src/icons/untitled-ui/duocolor/message-heart-square";
import MedicalCircleIcon from "src/icons/untitled-ui/duocolor/medical-circle";
import BankIcon from "src/icons/untitled-ui/duocolor/bank";
import Phone01Icon from "src/icons/untitled-ui/duocolor/phone-01";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import Home03Icon from "src/icons/untitled-ui/duocolor/home-03";
import BoxIcon from "src/icons/untitled-ui/duocolor/box";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FileShield02Icon from "src/icons/untitled-ui/duocolor/file-shield-02";
import FileHeart02Icon from "src/icons/untitled-ui/duocolor/file-heart-02";
import FilePlus02Icon from "src/icons/untitled-ui/duocolor/file-plus-02";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

import { VideoAskDialog } from "src/sections/dashboard/videoask-dialog";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";

import { useAuth } from "src/hooks/use-auth";
import { useEstateDocumentStore } from "src/hooks/snug/use-estateDocs";
import { paths } from "src/paths";
import { useOtherUserDataStore } from "src/hooks/snug/use-otherUserData";
import { LoadingButton } from "@mui/lab";
import { useVideoAskRecordings } from "src/hooks/snug/use-videoask";
import { useEstatePlanSummaryStore } from "src/hooks/snug/use-summary";
import { ConfirmAccessDialog } from "src/sections/dashboard/overview/confirm-access-dialog";

export const Home: FC = () => {
  const [popoverId, setPopoverId] = useState<string | null>(null);
  const popover = usePopover<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dialog = useDialog();

  const { user } = useAuth();
  const { assumedAccountRole, clientRole, clientRoleLoading, userData, handleAcceptDomainLeadRequest } = useUserDataProvider();
  const {
    otherUserData,
    otherUserDataSavingNew,
    otherUserDataLoading,
    handleAddOrUpdateOtherUserData,
  } = useOtherUserDataStore(userData?.ud_id || null);
  const { handleCreateNewSummaryAndPoll, pollingStatus } =
    useEstatePlanSummaryStore(userData?.ud_id || null);
  const { deleteRecording, fetchRecordings } =
    useVideoAskRecordings(userData?.ud_id || null);
  const navigate = useNavigate();
  const estateDocStore = useEstateDocumentStore(userData?.ud_id || null);

  const [content, setContent] = useState<string>(
    otherUserData.words_of_wisdom || ""
  );

  // Function to handle content change
  const handleContentChange = (content: string) => {
    setContent(content);
  };

  const handleClear = () => {
    setContent("");
  };

  const handleSave = () => {
    // Here we would include the editor content in the object we want to save
    const userDataToSave: OtherUserDataObj = {
      // ...other user data fields
      id: otherUserData.id || null,
      words_of_wisdom: content,
    };
    handleAddOrUpdateOtherUserData(userDataToSave);
  };

  useEffect(() => {
    setContent(otherUserData?.words_of_wisdom || "");
  }, [otherUserData]);

  const mostRecentOfficialHCD =
    estateDocStore.hcds.filter(
      (d) => d.type === "HEALTHCARE_DIRECTIVE" && d.status !== "IN_PROGRESS"
    )[0] || null;

  const mostRecentOfficialWillOrTrust =
    estateDocStore.willsAndTrusts.filter(
      (d) => d.status !== "IN_PROGRESS"
    )[0] || null;

  const mostRecentOfficialFpoa =
    estateDocStore.fpoas.filter(
      (d) =>
        d.type === "FINANCIAL_POWER_OF_ATTORNEY" && d.status !== "IN_PROGRESS"
    )[0] || null;

  const advisorUploadProcessing =
    estateDocStore.estateDocuments.filter(
      (d) =>
        d.document_source === "ADVISOR_UPLOAD" &&
        d.status === "ANALYSIS_PENDING"
    ).length > 0;

  usePageView();

  const parseFirstName = (name: string | undefined): string => {
    if (name) {
      const nameArray = name.split(" ");
      return nameArray[0];
    } else {
      return "";
    }
  };

  const [dialogConfirmAccessOpen, setDialogConfirmAccessOpen] = useState(false);
  useEffect(() => {
    if (clientRole?.lead_status === 'REQUESTED') {
      setDialogConfirmAccessOpen(true);
    }
  }, [clientRole]);

  return (
    <>
      <Seo title="Total Estate Planning" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          pb: 8,
          px: 0,
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
          >
            <Stack spacing={1.5} sx={{ px: 4 }}>
              <Typography color="inherit" variant="h3">
                {userData?.full_name
                  ? `Welcome, ${parseFirstName(userData?.full_name)}!`
                  : `Welcome!`}
              </Typography>
              {/* <Typography
                color="text.secondary"
                sx={{ mt: 2, pr: 3 }}
                variant="body2"
              >
                Congratulations on taking this step to protect your family,
                friends, and loved ones.
              </Typography> */}
            </Stack>
            <Stack direction="column" alignItems={{ xs: "start", sm: "end" }}>
              <LoadingButton
                variant="text"
                loading={pollingStatus === "PENDING"}
                disabled={pollingStatus === "PENDING"}
                loadingPosition="start"
                onClick={() => handleCreateNewSummaryAndPoll()}
                color={
                  pollingStatus === "SUCCESS"
                    ? "success"
                    : pollingStatus === "FAILURE"
                    ? "error"
                    : "inherit"
                }
                sx={{
                  ml: { xs: 2.5, sm: 0 },
                  mt: { xs: 2, sm: 0 },
                  whiteSpace: "nowrap",
                }}
                startIcon={
                  pollingStatus === "SUCCESS" ? (
                    <DownloadDoneRoundedIcon />
                  ) : (
                    <>
                      {pollingStatus === "FAILURE" ? (
                        <WarningAmberRoundedIcon />
                      ) : (
                        <FileDownloadOutlinedIcon />
                      )}
                    </>
                  )
                }
              >
                {assumedAccountRole === "AUTH_READONLY" ? (
                  <>
                    {pollingStatus === "PENDING"
                      ? "Generating plan"
                      : pollingStatus === "SUCCESS"
                      ? "Success"
                      : pollingStatus === "FAILURE"
                      ? "Something went wrong"
                      : "Download plan"}
                  </>
                ) : (
                  <>
                    {pollingStatus === "PENDING"
                      ? "Generating your plan"
                      : pollingStatus === "SUCCESS"
                      ? "Success"
                      : pollingStatus === "FAILURE"
                      ? "Something went wrong"
                      : "Download your plan"}
                  </>
                )}
              </LoadingButton>

              {pollingStatus === "SUCCESS" && (
                <Typography
                  variant="caption"
                  color="success.main"
                  sx={{ pr: { xs: 0, sm: 1 }, pl: { xs: 4, sm: 0 } }}
                >
                  Your plan has been downloaded.
                </Typography>
              )}
              {pollingStatus === "FAILURE" && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ pr: { xs: 0, sm: 1 }, pl: { xs: 4, sm: 0 } }}
                >
                  We will email your plan when ready.
                </Typography>
              )}
            </Stack>
          </Stack>
        </Container>

        <Box
          sx={{
            // backgroundImage:
            //   'url("https://s3.us-east-2.amazonaws.com/assets.getsnug.com/files/sand_opt.png")',
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // backgroundPosition: { xs: "left", md: "right" },
            mt: 2,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ px: 4 }}>
              <Stack spacing={1.5}>
                {assumedAccountRole === "AUTH_READONLY" ? (
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Essential documents
                  </Typography>
                ) : (
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Your essential documents
                  </Typography>
                )}
                {assumedAccountRole === "AUTH_READONLY" ? (
                  <Typography sx={{ mt: 2, pr: 3 }} variant="body2">
                    The key documents to reference for the estate plan.
                  </Typography>
                ) : (
                  <Typography sx={{ mt: 2, pr: 3 }} variant="body2">
                    Just a few key documents can provide all the protection you
                    need.
                  </Typography>
                )}
              </Stack>
              <Divider sx={{ mt: 3, mb: 4 }} />

              <Box sx={{ mt: 5 }}>
                <Grid
                  container
                  spacing={{
                    xs: 3,
                    lg: 4,
                  }}
                >
                  <Grid xs={12} sm={6} lg={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // border: "none",
                        cursor: "pointer",
                        borderRadius: 2,
                        backgroundColor: "#FAF9F8",
                      }}
                      onClick={() => navigate(paths.dashboard.lwt)}
                    >
                      <CardContent
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          pb: 2,
                        }}
                      >
                        
                        {clientRole?.recommendation_will &&
                          !(
                            mostRecentOfficialWillOrTrust ||
                            advisorUploadProcessing
                          ) && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              <Tooltip title="Your advisor recommends that you get a Will">
                                <IconButton>
                                  <SvgIcon
                                    sx={{
                                      color: "white",
                                      "& path": {
                                        fill: (theme) =>
                                          theme.palette.warning.main,
                                        fillOpacity: 1,
                                      },
                                    }}
                                  >
                                    <AlertCircleIcon />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}

                        {clientRole?.recommendation_trust &&
                          !(
                            mostRecentOfficialWillOrTrust ||
                            advisorUploadProcessing
                          ) && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              <Tooltip title="Your advisor recommends that you get a Trust">
                                <IconButton>
                                  <SvgIcon
                                    sx={{
                                      color: "white",
                                      "& path": {
                                        fill: (theme) =>
                                          theme.palette.warning.main,
                                        fillOpacity: 1,
                                      },
                                    }}
                                  >
                                    <AlertCircleIcon />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        <Stack direction="column" spacing={2}>
                        <SvgIcon fontSize="large">
                              <FileShield02Icon />
                            </SvgIcon>

                          <Typography color="text.primary" variant="h6">
                            Wills & Trusts
                          </Typography>
                        </Stack>
                        {assumedAccountRole === "AUTH_READONLY" ? (
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            View current wills and trusts, previous versions,
                            and key details.
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              color="text.secondary"
                              sx={{ mt: 1, flex: 1 }}
                              variant="body2"
                            >
                              Wills and Trusts outline how your assets will be
                              distributed, ensuring your legacy is passed on
                              according to your wishes.
                            </Typography>
                            {mostRecentOfficialWillOrTrust ||
                            advisorUploadProcessing ? (
                              <Box sx={{width:'100%'}}>
                              <Chip
                                  label="Complete"
                                  icon={<CheckCircleIcon style={{ color: 'white' }} fontSize='small' />}
                                  style={{
                                    backgroundColor: green[500],
                                    color: 'white',
                                    padding: '0 8px',
                                  }}
                                  sx={{mt:2, width:'100%'}}
                                />
                                </Box>
                            ) : (
                              <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{ mt: 2 }}
                              >
                                <AccessTimeOutlinedIcon
                                  fontSize="small"
                                  sx={{ color: "#6C737F" }}
                                />
                                <Typography
                                  color="text.secondary"
                                  variant="caption"
                                >
                                  15 minutes
                                </Typography>
                              </Stack>
                            )}
                          </>
                        )}
                      </CardContent>
                      <Divider />
                      <CardActions>
                        {mostRecentOfficialWillOrTrust ||
                        advisorUploadProcessing ? (
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.lwt)}
                          >
                            View document
                          </Button>
                        ) : (
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.lwt)}
                          >
                            Get started
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid xs={12} sm={6} lg={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // border: "none",
                        cursor: "pointer",
                        borderRadius: 2,
                        backgroundColor: "#FAF9F8",
                      }}
                      onClick={() => navigate(paths.dashboard.fpoa)}
                    >
                      <CardContent
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          pb: 2,
                        }}
                      >
                        {clientRole?.recommendation_fpoa &&
                          !(
                            mostRecentOfficialFpoa || advisorUploadProcessing
                          ) && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              <Tooltip title="Your advisor recommends that you get a Power of Attorney">
                                <IconButton>
                                  <SvgIcon
                                    sx={{
                                      color: "white",
                                      "& path": {
                                        fill: (theme) =>
                                          theme.palette.warning.main,
                                        fillOpacity: 1,
                                      },
                                    }}
                                  >
                                    <AlertCircleIcon />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        <Stack direction="column" spacing={2}>
                        <SvgIcon fontSize="large">
                              <FilePlus02Icon />
                            </SvgIcon>

                          <Typography color="text.primary" variant="h6">
                            Power of Attorney
                          </Typography>
                        </Stack>
                        {assumedAccountRole === "AUTH_READONLY" ? (
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            View the current power of attorney, previous
                            versions, and key details.
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              color="text.secondary"
                              sx={{ mt: 1, flex: 1 }}
                              variant="body2"
                            >
                              A Power of Attorney grants someone legal authority
                              to manage your finances and affairs if you are
                              unable to do so.
                            </Typography>
                            {mostRecentOfficialFpoa ||
                            advisorUploadProcessing ? (
                              <Box sx={{width:'100%'}}>
                              <Chip
                                  label="Complete"
                                  icon={<CheckCircleIcon style={{ color: 'white' }} fontSize='small' />}
                                  style={{
                                    backgroundColor: green[500],
                                    color: 'white',
                                    padding: '0 8px',
                                  }}
                                  sx={{mt:2, width:'100%'}}
                                />
                                </Box>
                            ) : (
                              <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{ mt: 2 }}
                              >
                                <AccessTimeOutlinedIcon
                                  fontSize="small"
                                  sx={{ color: "#6C737F" }}
                                />
                                <Typography
                                  color="text.secondary"
                                  variant="caption"
                                >
                                  4 minutes
                                </Typography>
                              </Stack>
                            )}
                          </>
                        )}
                      </CardContent>
                      <Divider />
                      <CardActions>
                        {mostRecentOfficialFpoa || advisorUploadProcessing ? (
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.fpoa)}
                          >
                            View document
                          </Button>
                        ) : (
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.fpoa)}
                          >
                            Get started
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid xs={12} sm={6} lg={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // border: "none",
                        cursor: "pointer",
                        borderRadius: 2,
                        backgroundColor: "#FAF9F8",
                      }}
                      onClick={() => navigate(paths.dashboard.hcd)}
                    >
                      <CardContent
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          pb: 2,
                        }}
                      >
                        {clientRole?.recommendation_hcd &&
                          !(
                            mostRecentOfficialHCD || advisorUploadProcessing
                          ) && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              <Tooltip title="Your advisor recommends that you get a Health Care Directive">
                                <IconButton>
                                  <SvgIcon
                                    sx={{
                                      color: "white",
                                      "& path": {
                                        fill: (theme) =>
                                          theme.palette.warning.main,
                                        fillOpacity: 1,
                                      },
                                    }}
                                  >
                                    <AlertCircleIcon />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        <Stack direction="column" spacing={2}>
                        <SvgIcon fontSize="large">
                              <FileHeart02Icon />
                            </SvgIcon>

                          <Typography color="text.primary" variant="h6">
                            Health Care Directive
                          </Typography>
                        </Stack>
                        {assumedAccountRole === "AUTH_READONLY" ? (
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            View the current health care directive, previous
                            versions, and key details.
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              color="text.secondary"
                              sx={{ mt: 1, flex: 1 }}
                              variant="body2"
                            >
                              A Health Care Directive specifies your medical
                              treatment preferences and appoints someone to make
                              decisions if you're incapacitated.
                            </Typography>
                            {mostRecentOfficialHCD ||
                            advisorUploadProcessing ? (
                              <Box sx={{width:'100%'}}>
                              <Chip
                                  label="Complete"
                                  icon={<CheckCircleIcon style={{ color: 'white' }} fontSize='small' />}
                                  style={{
                                    backgroundColor: green[500],
                                    color: 'white',
                                    padding: '0 8px',
                                  }}
                                  sx={{mt:2, width:'100%'}}
                                />
                                </Box>
                            ) : (
                              <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{ mt: 2 }}
                              >
                                <AccessTimeOutlinedIcon
                                  fontSize="small"
                                  sx={{ color: "#6C737F" }}
                                />
                                <Typography
                                  color="text.secondary"
                                  variant="caption"
                                >
                                  6 minutes
                                </Typography>
                              </Stack>
                            )}
                          </>
                        )}
                      </CardContent>
                      <Divider />
                      <CardActions>
                        {mostRecentOfficialHCD || advisorUploadProcessing ? (
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.hcd)}
                          >
                            View document
                          </Button>
                        ) : (
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.hcd)}
                          >
                            Get started
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="lg">
          <Stack spacing={3} sx={{ mb: 8, mt: 4, px: 4 }}>
            <Stack spacing={1.5}>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Your letter to family
              </Typography>
              <Typography sx={{ mt: 2, pr: 3 }} variant="body2">
                A letter to family can provide your thoughts and wishes for
                future generations.
              </Typography>
            </Stack>

            <Divider sx={{ mt: 3, mb: 4 }} />

            <Box sx={{ mt: 5 }}></Box>

            <Grid
              container
              spacing={{
                lg: 0,
              }}
            >
              <Grid xs={12} sm={6} lg={8}>
                <Stack spacing={3}>
                  <QuillEditor
                    value={content}
                    onChange={handleContentChange}
                    readOnly={
                      assumedAccountRole === "AUTH_READONLY" ||
                      otherUserDataLoading
                    }
                    placeholder="Write your letter to family"
                    sx={{ height: 330, mr: 4 }}
                    modules={
                      assumedAccountRole === "AUTH_READONLY"
                        ? { toolbar: false }
                        : { toolbar: true }
                    }
                  />
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      variant="contained"
                      disabled={
                        assumedAccountRole === "AUTH_READONLY" ||
                        otherUserDataLoading
                      }
                      loading={otherUserDataSavingNew}
                      startIcon={<MarkEmailReadRoundedIcon />}
                      onClick={handleSave}
                      sx={{ borderRadius: 100 }}
                    >
                      Save changes
                    </LoadingButton>
                    <Button
                      variant="text"
                      disabled={assumedAccountRole === "AUTH_READONLY"}
                      color="inherit"
                      onClick={handleClear}
                      sx={{ borderRadius: 100 }}
                    >
                      Clear
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              <Grid xs={0} sm={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                    overflowX: "hidden",
                    overflowY: "hidden",
                    width: "100%",
                  }}
                >
                  <Scrollbar sx={{ maxHeight: "420px", borderRadius: 2.5 }}>
                    <Card
                      variant="outlined"
                      sx={{
                        backgroundColor: "#FAF9F8",
                        borderRadius: 2.5,
                        p: 2,
                        px: 1,
                        maxHeight: "420px",
                        overflowY: "scroll",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          px: 3,
                          pt: 1.5,
                          pb: 1,
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                      >
                        Need some inspiration?
                      </Typography>

                      <Box
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          px: 3,
                          pt: 1.5,
                        }}
                      >
                        <Card
                          elevation={1}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "neutral.800"
                                : "background.paper",

                            p: 3,
                            userSelect: "none",
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "neutral.700"
                                  : "neutral.50",
                            },
                            "&.MuiPaper-elevation1": {
                              boxShadow: 1,
                            },
                          }}
                        >
                          <Typography variant="subtitle2">
                            What are your wishes for future generations?
                          </Typography>
                        </Card>
                      </Box>

                      <Box
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          px: 3,
                          pt: 1.5,
                        }}
                      >
                        <Card
                          elevation={1}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "neutral.800"
                                : "background.paper",

                            p: 3,
                            userSelect: "none",
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "neutral.700"
                                  : "neutral.50",
                            },
                            "&.MuiPaper-elevation1": {
                              boxShadow: 1,
                            },
                          }}
                        >
                          <Typography variant="subtitle2">
                            What are the most important pieces of wisdom you've
                            learned?
                          </Typography>
                        </Card>
                      </Box>

                      <Box
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          px: 3,
                          pt: 1.5,
                        }}
                      >
                        <Card
                          elevation={1}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "neutral.800"
                                : "background.paper",

                            p: 3,
                            userSelect: "none",
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "neutral.700"
                                  : "neutral.50",
                            },
                            "&.MuiPaper-elevation1": {
                              boxShadow: 1,
                            },
                          }}
                        >
                          <Typography variant="subtitle2">
                            Are there stories behind any family heirlooms?
                          </Typography>
                        </Card>
                      </Box>

                      <Box
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          px: 3,
                          pt: 1.5,
                        }}
                      >
                        <Card
                          elevation={1}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "neutral.800"
                                : "background.paper",

                            p: 3,
                            userSelect: "none",
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "neutral.700"
                                  : "neutral.50",
                            },
                            "&.MuiPaper-elevation1": {
                              boxShadow: 1,
                            },
                          }}
                        >
                          <Typography variant="subtitle2">
                            Are there clarifications to avoid family conflict?
                          </Typography>
                        </Card>
                      </Box>

                      <Box
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          px: 3,
                          pt: 1.5,
                          pb: 1.5,
                        }}
                      >
                        <Card
                          elevation={1}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "neutral.800"
                                : "background.paper",

                            p: 3,
                            userSelect: "none",
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "neutral.700"
                                  : "neutral.50",
                            },
                            "&.MuiPaper-elevation1": {
                              boxShadow: 1,
                            },
                          }}
                        >
                          <Typography variant="subtitle2">
                            How do you want to be remembered in 100 years?
                          </Typography>
                        </Card>
                      </Box>
                    </Card>
                  </Scrollbar>
                </Box>
              </Grid>
            </Grid>
          </Stack>

          <Stack spacing={3} sx={{ mb: 8, mt: 4, px: 4 }}>
            <Stack spacing={3}>
              <Stack spacing={1.5}>
                {assumedAccountRole === "AUTH_READONLY" ? (
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Other important details
                  </Typography>
                ) : (
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Your life's details
                  </Typography>
                )}

                {assumedAccountRole === "AUTH_READONLY" ? (
                  <Typography sx={{ mt: 2, pr: 3 }} variant="body2">
                    View and reference all of life's other important details in
                    one place.
                  </Typography>
                ) : (
                  <Typography sx={{ mt: 2, pr: 3 }} variant="body2">
                    Organizing all of life's details in one place, to leave a
                    legacy and not a mess.
                  </Typography>
                )}
              </Stack>

              <Divider sx={{ mt: 3, mb: 4 }} />

              <Box sx={{ mt: 5 }}></Box>

              <Stack spacing={3}>
                <Box>
                  <Grid
                    container
                    spacing={{
                      xs: 3,
                      lg: 4,
                    }}
                  >
                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          // border: "none",
                          cursor: "pointer",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.personal)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <Users03Icon />
                            </SvgIcon>

                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Personal
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            Personal contacts, pets, advisors, and personal
                            history.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.personal)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          // border: "none",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.legal)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <BoxIcon />
                            </SvgIcon>
                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Legal
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            Estate planning, marriage and divorce, business
                            documents.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.legal)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          // border: "none",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.medical)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <MedicalCircleIcon />
                            </SvgIcon>
                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Medical
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            Medical contacts, history, allergies, and current
                            medications.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.medical)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          // border: "none",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.financial)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <BankIcon />
                            </SvgIcon>
                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Financial
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            List of assets, debts, and insurance to ease estate
                            settlement effort.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.financial)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          // border: "none",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.property)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <Home03Icon />
                            </SvgIcon>
                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Property
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            Inventory of vehicles, real estate, family
                            heirlooms, and other personal property.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.property)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          // border: "none",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.digital)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <Phone01Icon />
                            </SvgIcon>
                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Digital
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            Social media, cloud storage, photo services, and
                            more digital assets.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.digital)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid xs={12} sm={4} lg={3}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                          // border: "none",
                          backgroundColor: "#FAF9F8",
                        }}
                        onClick={() => navigate(paths.dashboard.legacy)}
                      >
                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            pb: 2,
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <SvgIcon>
                              <MessageHeartSquareIcon />
                            </SvgIcon>
                            <Typography
                              color="text.primary"
                              variant="subtitle1"
                            >
                              Legacy
                            </Typography>
                          </Stack>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1, flex: 1 }}
                            variant="body2"
                          >
                            Family history and words of wisdom to pass along to
                            the next generation.
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="inherit"
                            endIcon={
                              <SvgIcon>
                                <ArrowForwardOutlinedIcon />
                              </SvgIcon>
                            }
                            size="small"
                            onClick={() => navigate(paths.dashboard.legacy)}
                          >
                            Get started
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <VideoDropDownMenu
        anchorEl={anchorEl}
        onClose={() => {
          setPopoverId(null);
          setAnchorEl(null);
          popover.handleClose();
        }}
        recordingId={popoverId}
        open={popover.open}
        handleRemoveVideo={deleteRecording}
      />
      <VideoAskDialog
        // on close, handleClose, then wait a second and to then call fetchRecordings
        onClose={() => {
          dialog.handleClose();
          if (userData?.ud_id) {
            setTimeout(() => fetchRecordings(userData.ud_id || ""), 1000);
            setTimeout(() => fetchRecordings(userData.ud_id || ""), 3000);
            setTimeout(() => fetchRecordings(userData.ud_id || ""), 5000);
            setTimeout(() => fetchRecordings(userData.ud_id || ""), 7000);
            setTimeout(() => fetchRecordings(userData.ud_id || ""), 10000);
          }
        }}
        open={dialog.open}
        email={user?.username || null}
      />
      <ConfirmAccessDialog
        onClose={() => setDialogConfirmAccessOpen(false)}
        open={dialogConfirmAccessOpen}
        clientRole={clientRole}
        clientRoleLoading={clientRoleLoading}
        handleAcceptDomainLeadRequest={handleAcceptDomainLeadRequest}
      />
    </>
  );
};
