import type { FC } from "react";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { Button, CircularProgress } from "@mui/material";
import CheckCircleSolidIcon from "src/icons/untitled-ui/duocolor/check-circle-solid";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { TargetPeopleRole, UserDataObj } from "src/types/snugtotal";
import { peopleRoleRelationshipMap } from "src/utils/snug";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";

interface TenantPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  tenants: TargetPeopleRole[];
  handleSelect?: (authorization: TargetPeopleRole) => void;
  onAddPlanClick: () => void;
  onVerifyUserClick: (id: string) => void;
  loadingViewingUserData?: boolean;
  userData: UserDataObj | null;
}

export const TenantPopover: FC<TenantPopoverProps> = (props) => {
  const {
    anchorEl,
    onClose,
    open = false,
    tenants,
    handleSelect,
    onAddPlanClick,
    onVerifyUserClick,
    loadingViewingUserData,
    userData,
    ...other
  } = props;
  const router = useRouter();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { p: 1 } }}
      {...other}
    >
      <Box sx={{ pt: 1, mb: 2 }}>
        {loadingViewingUserData ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Button
              size="small"
              color="primary"
              variant="contained"
              sx={{ p: 1 }}
              onClick={props.onAddPlanClick}
              startIcon={<PersonAddOutlinedIcon fontSize="small" />}
              fullWidth
            >
              Add plan
            </Button>
          </>
        )}
      </Box>
      <Divider sx={{ mb: 2 }} />
      {tenants.map((tenant) => (
        <>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            {...props}
            sx={{
              backgroundColor:
                userData?.ud_id === tenant.user_data?.ud_id
                  ? "#f1fef3"
                  : "#ffffff",
              py: 1,
              m: 0.5,
              px: 2,
              cursor: "pointer",
              borderRadius: 1,
              minWidth: "200px",
            }}
            key={tenant.user_data?.ud_id}
            onClick={() => {
              if (loadingViewingUserData) return;
              handleSelect && handleSelect(tenant);

              router.push(paths.dashboard.index);
            }}
          >
            {/* <Avatar alt={tenant.user_data?.full_name} src="/static/images/avatar/1.jpg" /> */}
            {userData?.ud_id === tenant.user_data?.ud_id ? (
              <SvgIcon fontSize="small" sx={{ color: "#3e8a28" }}>
                <CheckCircleSolidIcon />
              </SvgIcon>
            ) : (
              <></>
            )}
            <Box
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                width: "100%",
                lineHeight: 0.8,
              }}
            >
              <Typography
                color="inherit"
                variant="body2"
                sx={{
                  fontWeight: 600,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {tenant.user_data?.full_name}
              </Typography>
              <Typography color="neutral.400" sx={{ fontSize: 13 }}>
                {peopleRoleRelationshipMap[tenant.role || "AUTH_COLLABORATOR"]}
              </Typography>
            </Box>
            {tenant.role === "SNUG_OWNER" || tenant.is_demo_client ? (
              <></>
            ) : !tenant.user_data?.has_auth_user ? (
              <Button
                size="small"
                variant="text"
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onVerifyUserClick(tenant?.user_data?.ud_id || "");
                }}
              >
                Invite
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </>
      ))}
    </Popover>
  );
};
