import { useCallback } from "react";

const axios = require("axios").default;

const snugAuthBaseURL = process.env.REACT_APP_SNUG_AUTH_URL;

export enum AuthGenericPath {
  PAYMENTS = "user/stripe/customer/payments/",
  V3_PAYMENT_REQUIREMENT = "user/stripe/payment/{product_id}/",
}

export const auth_api_instance = axios.create({
  baseURL: snugAuthBaseURL,
});

type V3UrlParams = {
  product_id: string;
};

const replacePathPlaceholders = (pathTemplate: string, params: V3UrlParams) => {
  return pathTemplate.replace("{product_id}", params.product_id);
};

export const useV3GenericGetData = <T>(genericPath: string) => {
  const genericGetData = useCallback(
    async (params: V3UrlParams, queryParams: Object = {}): Promise<T> => {
      const path = replacePathPlaceholders(genericPath, params);
      const response = await auth_api_instance.get(path, {
        params: queryParams,
      });
      return response.data.data as T;
    },
    [genericPath]
  );
  return genericGetData;
};

export const updateFirstName = async (first_name: string) => {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("s_at"),
    },
  };
  const response = await auth_api_instance.put(
    "user/first-name/",
    { first_name: first_name },
    config
  );
  return response.data;
};

export const signUp = async (email: string, password: string, name: string) => {
  const response = await auth_api_instance.post("user/create/", {
    email: email.toLowerCase(),
    password: password,
    first_name: name,
  });
  return response.data;
};

export const sendVerifyEmailEmail = async (
  username: string,
  pwBypass?: boolean
) => {
  const response = await auth_api_instance.post("user/email-verify/", {
    username: username.toLowerCase(),
    domain: null, //can manually override redirect domain on email verify is necessary
    pwBypass: pwBypass,
  });
  return response;
};
export const emailVerify = async (
  token: string,
  password: string | null,
  first_name: string | null
) => {
  const response = await auth_api_instance.put("user/email-verify/", {
    token: token,
    password: password,
    first_name: first_name,
  });
  return response;
};

export const getUser = async (token: string) => {
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  const response = await auth_api_instance.get("user/detail/", config);
  return response.data;
};

export const getToken = async (email: string, password: string) => {
  const response = await auth_api_instance.post("token/", {
    username: email.toLowerCase(),
    password: password,
  });
  return response;
};

export const verifyToken = async (
  token: string,
  controller: AbortController
) => {
  const response = await auth_api_instance.post(
    "token/verify/",
    { token: token },
    { signal: controller.signal }
  );
  return response;
};

export const refreshToken = async (refresh: string) => {
  const response = await auth_api_instance.post("token/refresh/", {
    refresh: refresh,
  });
  return response;
};

export const resetPassWordTokenAndEmail = async (email: string | null) => {
  if (!email) {
    throw new Error("No email provided");
  }
  const response = await auth_api_instance.post("password_reset/", {
    email: email.toLowerCase(),
    redirect_app_domain: window.location.origin.replace('https://', '').replace('http://', '') || process.env.REACT_APP_DEFAULT_SNUG_DOMAIN,
  });
  return response;
};

export const resetPassword = async (token: string, newPassword: string) => {
  const response = await auth_api_instance.post("password_reset/confirm/", {
    token: token,
    password: newPassword,
  });
  return response;
};

auth_api_instance.interceptors.request.use((config: any) => {
  if (localStorage.getItem("s_at")) {
    config.headers.Authorization = "Bearer " + localStorage.getItem("s_at");
  }
  return config;
});
