import type { FC } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";


interface TopDemoProps {
  onMobileNavOpen?: () => void;
}

export const TopDemo: FC<TopDemoProps> = () => {

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "fixed",

          top: 0,
          width: {
            md: `100%`,
          },
          zIndex: 9990,
        }}
      >
          <Box
            sx={{
              backgroundColor: "#111822",
              py: 0.7,
              color: "primary.contrastText",
            }}
          >
            <Container maxWidth={"lg"}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                textAlign={"left"}
                justifyContent={"center"}
              >
                  <Typography variant="button" sx={{ textTransform: "none" }}>
                    This is a demo account
                  </Typography>
                
                <Button
                  endIcon={<EastOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
                />
              </Stack>
            </Container>
          </Box>
      </Box>
    </>
  );
};

TopDemo.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
