import * as Yup from "yup";
import { useFormik } from "formik";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { paths } from "src/paths";
import { useMounted } from "src/hooks/use-mounted";
import { resetPassWordTokenAndEmail } from "src/api/snugtotal/auth";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useRouter } from "src/hooks/use-router";
import { useSearchParams } from "react-router-dom";


interface Values {
  email: string;
  submit: null;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const Page = () => {
  const router = useRouter();
  const isMounted = useMounted();
  const [searchParams] = useSearchParams();
  const emailFromParams = searchParams.get('email') || '';
  const [buttonText, setButtonText] = useState<string>("Continue");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const initialValues: Values = {
    email: emailFromParams,
    submit: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await resetPassWordTokenAndEmail(values.email);
        if (isMounted()) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          setButtonText("Reset password email has been sent");
          setEmailSent(true);
          setTimeout(() => {
            router.push(paths.auth.jwt.entry);
          }, 5000);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({
            submit: "There was an unexpected error. Please try again.",
          });
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <>
      <Seo title="Forgot Password" />
      <div>
      <Card variant='outlined' sx={{borderRadius:1, p:3, px:4, pb:4}}>
          <CardHeader 
          sx={{ pb: 0 }} 
          title={<Typography variant="h5">Reset your password</Typography>}
          subheader={
            <Typography color="text.secondary" variant="body2" sx={{ mt: 1 }}>
              We'll send a magic link to your email. Please click the link to reset your password.
            </Typography>
          } />         
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
              />
              <LoadingButton
                fullWidth
                disabled={emailSent}
                size="large"
                sx={{ mt: 4 }}
                loading={formik.isSubmitting}
                type="submit"
                variant="contained"
              >
                {buttonText}
              </LoadingButton>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Page;
