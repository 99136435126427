import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { Home } from "src/pages/dashboard/index";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const LegalPage = lazy(() => import("src/pages/dashboard/legal"));
const FinancialPage = lazy(() => import("src/pages/dashboard/financial"));
const TaxesPage = lazy(() => import("src/pages/dashboard/taxes"));
const PersonalPage = lazy(() => import("src/pages/dashboard/personal"));
const DigitalPage = lazy(() => import("src/pages/dashboard/digital"));
const InsurancePage = lazy(() => import("src/pages/dashboard/insurance"));
const PropertyPage = lazy(() => import("src/pages/dashboard/property"));
// const MyBenefitsPage = lazy(() => import("src/pages/dashboard/mybenefits"));
const LegacyPage = lazy(() => import("src/pages/dashboard/legacy"));
const MedicalPage = lazy(() => import("src/pages/dashboard/medical"));

const WillsAndTrustsHome = lazy(
  () => import("src/pages/dashboard/documents/wills-and-trusts/index")
);
const WillsAndTrustsCreate = lazy(
  () => import("src/pages/dashboard/documents/wills-and-trusts/create")
);
const PowerOfAttorneyHome = lazy(
  () => import("src/pages/dashboard/documents/power-of-attorney/index")
);
const PowerOfAttorneyCreate = lazy(
  () => import("src/pages/dashboard/documents/power-of-attorney/create")
);
const HealthCareDirectiveHome = lazy(
  () => import("src/pages/dashboard/documents/health-care-directive/index")
);
const HealthCareDirectiveCreate = lazy(
  () => import("src/pages/dashboard/documents/health-care-directive/create")
);

// Other
const AccountPage = lazy(() => import("src/pages/dashboard/account"));

export const dashboardRoutes: RouteObject[] = [
  {
    path: "",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        path: "/",
        element: <Home />,
      },
      {
        path: "account/:tab?",
        element: <AccountPage />,
      },
      {
        path: "legal",
        element: <LegalPage />,
      },
      {
        path: "medical",
        element: <MedicalPage />,
      },
      {
        path: "legacy",
        element: <LegacyPage />,
      },
      // {
      //   path: "benefits",
      //   element: <MyBenefitsPage />,
      // },
      {
        path: "financial",
        element: <FinancialPage />,
      },
      {
        path: "taxes",
        element: <TaxesPage />,
      },
      {
        path: "property",
        element: <PropertyPage />,
      },
      {
        path: "financial",
        element: <FinancialPage />,
      },
      {
        path: "personal",
        element: <PersonalPage />,
      },
      {
        path: "digital",
        element: <DigitalPage />,
      },
      {
        path: "insurance",
        element: <InsurancePage />,
      },
      {
        path: "wills-and-trusts",
        element: <WillsAndTrustsHome />,
      },
      {
        path: "financial-power-of-attorney",
        element: <PowerOfAttorneyHome />,
      },
      {
        path: "health-care-directive",
        element: <HealthCareDirectiveHome />,
      },
      {
        path: "wills-and-trusts/create/:estateDocId",
        element: <WillsAndTrustsCreate />,
      },
      {
        path: "financial-power-of-attorney/create/:estateDocId",
        element: <PowerOfAttorneyCreate />,
      },
      {
        path: "health-care-directive/create/:estateDocId",
        element: <HealthCareDirectiveCreate />,
      },
    ],
  },
];
