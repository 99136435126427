import { useCallback, useEffect, useReducer } from "react";
import {
  GenericPath,
  useV3GenericGetData,
  useV3Create,
  useV3Update,
} from "src/api/snugtotal/estate_app";
import { UserDataObj } from "src/types/snugtotal";

type UserDataStoreAction =
  | { type: "SET_USERDATA"; payload: UserDataObj }
  | { type: "SET_USERDATA_INITIAL_LOADING"; payload: boolean }
  | { type: "SET_USERDATA_SAVING"; payload: boolean };

interface UserDataStoreState {
  userData: UserDataObj;
  userDataInitialLoading: boolean;
  userDataSaving: boolean;
}

const userDataStoreReducer = (
  state: UserDataStoreState,
  action: UserDataStoreAction
): UserDataStoreState => {
  switch (action.type) {
    case "SET_USERDATA":
      return { ...state, userData: action.payload };
    case "SET_USERDATA_INITIAL_LOADING":
      return { ...state, userDataInitialLoading: action.payload };
    case "SET_USERDATA_SAVING":
      return { ...state, userDataSaving: action.payload };
    default:
      return state;
  }
};

// This should be used once in the onboarding guard to load the user data. If you need
// to retrieve userData you can use the context provider in the dashboard routes.
export const useUserDataStore = (onMountLoad?: boolean) => {
  const [state, dispatch] = useReducer(userDataStoreReducer, {
    userData: {
      ud_id: null,
    },
    userDataInitialLoading: true,
    userDataSaving: false,
  });
  const genericGetData = useV3GenericGetData<UserDataObj>(
    GenericPath.USER_DATA_V3
  );
  const createUserData = useV3Create<UserDataObj>(GenericPath.USER_DATA_V3);
  const updateUserData = useV3Update<UserDataObj>(GenericPath.USER_DATA_V3);

  const handleAddOrEditOwnerUserData = useCallback(
    async (userData: UserDataObj): Promise<UserDataObj | null> => {
      try {
        dispatch({ type: "SET_USERDATA_SAVING", payload: true });
        const params = { ud_id: "", entity: "user_data" };
        if (userData.ud_id) {
          const res = await updateUserData(params, userData);
          dispatch({ type: "SET_USERDATA", payload: res });
          return res;
        } else {
          const res = await createUserData(params, userData);
          dispatch({ type: "SET_USERDATA", payload: res });
          return res;
        }
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        dispatch({ type: "SET_USERDATA_SAVING", payload: false });
      }
    },
    [createUserData, updateUserData]
  );

  const handleGetUserData =
    useCallback(async (): Promise<UserDataObj | null> => {
      try {
        const params = { ud_id: "", entity: "user_data" };
        const response = await genericGetData(params);
        dispatch({ type: "SET_USERDATA", payload: response });
        return response; // return the response
      } catch (err) {
        console.error(err);
        return null;
      } finally {
        dispatch({ type: "SET_USERDATA_INITIAL_LOADING", payload: false });
      }
    }, [genericGetData]);

  useEffect(() => {
    if (onMountLoad && !state.userData?.ud_id) {
      handleGetUserData();
    }

  }, [onMountLoad, state.userData?.ud_id, handleGetUserData]);

  return {
    ...state,
    handleGetUserData,
    handleAddOrEditOwnerUserData,
  };
};
