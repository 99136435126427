import type { FC } from "react";
import PropTypes from "prop-types";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import SvgIcon from "@mui/material/SvgIcon";

interface VideoAskDialogProps {
  email: string | null;
  onClose?: () => void;
  open?: boolean;
}

const videoaskMessageCode = process.env.REACT_APP_VIDEOASK_MESSAGE_CODE;

export const VideoAskDialog: FC<VideoAskDialogProps> = (props) => {
  const { onClose, open = false, email } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            p: 2,
          }}
        >
          <Button
            color="inherit"
            startIcon={
              <SvgIcon>
                <ArrowLeftIcon />
              </SvgIcon>
            }
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, px:4 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              mb: 6,
              mt: 2,
            }}
          >
            {/* display iframe if email is present, else present a bueatifully formatted error message that there is a non user error and they should try back again shortly */}
            {email ? (
              <iframe
                title="VideoAsk"
                src={`https://www.videoask.com/${videoaskMessageCode}#username=${email}`}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="600px"
                style={{ border: "none", borderRadius: "24px" }}
              ></iframe>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <h1>Oops!</h1>
                <p>
                  It looks like there was an unintended error. Please try again
                  later.
                </p>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

VideoAskDialog.propTypes = {
  // @ts-ignore
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
