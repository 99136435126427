import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useUserDataStore } from "src/hooks/snug/use-userData";
import { useAuth } from "src/hooks/use-auth";
import { SplashScreen } from "src/components/splash-screen";

interface OnboardingGuardProps {
  children: ReactNode;
}

export const OnboardingGuard: FC<OnboardingGuardProps> = (props) => {
  // This is a leftover from when onboarding created the userdata object but now we've moved that forward in the process.
  // But this will still catch cases where userData was not created.
  const { children } = props;
  const { user, isAuthenticated } = useAuth();
  const { handleGetUserData, handleAddOrEditOwnerUserData } = useUserDataStore();
  const [checked, setChecked] = useState(false);

  const check = useCallback(async () => {
    let userData = await handleGetUserData(); // Call the function from the hook and set userData
    if (!userData?.ud_id) {
      userData = await handleAddOrEditOwnerUserData({
        ud_id: null, // This is null because we are creating a new user data
        full_name: user?.first_name,
        contact_email: user?.username?.toLocaleLowerCase().trim(),
      });
    }

    // let creation_domain = userData?.creation_domain || 'app.getsnug.com'

    // if (creation_domain === 'dashboard.getsnug.com') {
    //   creation_domain = 'app.getsnug.com'
    // }

    // // if userData's.creation_domain value does not equal the current domain, redirect to the correct domain with a toast message to inform the user and puase for 1 second so the user can read the message before redirecting
    // if (userData?.creation_domain !== window.location.hostname) {
    //   toast.error("You are being redirected to the correct domain.");
    //   setTimeout(() => {
    //     window.location.href = `https://${creation_domain}`;
    //   }
    //   , 1000);
    // }

    setChecked(true);
  }, [
    handleAddOrEditOwnerUserData,
    handleGetUserData,
    user?.first_name,
    user?.username,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      check();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!checked) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};
