// Remove if react-quill is not used
import "react-quill/dist/quill.snow.css";
// Remove if react-draft-wysiwyg is not used
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Remove if simplebar is not used
import "simplebar-react/dist/simplebar.min.css";
// Remove if mapbox is not used
import "mapbox-gl/dist/mapbox-gl.css";

import { useEffect, type FC } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import { Provider as ReduxProvider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { RTL } from "src/components/rtl";
import { SplashScreen } from "src/components/splash-screen";
import { Toaster } from "src/components/toaster";
import {
  SettingsConsumer,
  SettingsProvider,
} from "src/contexts/settings-context";
import { AuthConsumer, AuthProvider } from "src/contexts/auth/jwt-context";
import { gtmConfig } from "src/config";
import { useNprogress } from "src/hooks/use-nprogress";
import { useAnalytics } from "src/hooks/use-analytics";
import { routes } from "src/routes";
import { createTheme } from "src/theme";

import { GlobalStyles } from "@mui/material";

// Remove if locales are not used
import "src/locales/i18n";
import { EstateAppApiProvider } from "./contexts/estateAppApi-context";
import { WhiteLabelConfigProvider } from "./contexts/config-context";
import { setFavicons } from "./utils/snug";

export const App: FC = () => {
  useAnalytics(gtmConfig);
  useNprogress();

  const element = useRoutes(routes);
  const location = useLocation(); // Get the current location

  const globalButtonStyles = {
    "&& .MuiButton-root": {
      borderRadius: "100px",
    },
  };

  useEffect(() => {
    setFavicons();
  }, []);

  function checkVersion() {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const currentVersion = process.env.REACT_APP_VERSION;
        const reloaded = localStorage.getItem("version_reloaded");

        if (meta.version !== currentVersion && !reloaded) {
          console.log(`New version available: ${meta.version}. Reloading...`);
          localStorage.setItem("version_reloaded", "true"); // Set the flag in localStorage
          window.location.reload();
        } else if (meta.version === currentVersion) {
          localStorage.removeItem("version_reloaded"); // Clear the flag if the versions match
        }
      })
      .catch((err) => console.error("Error checking version:", err));
  }

  useEffect(() => {
    checkVersion(); // Check version on every URL path change
  }, [location.pathname]); // Listen for changes in the URL path

  return (
    // <ReduxProvider store={store}> obviously readd if we use redux to manage state
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <WhiteLabelConfigProvider>
        <AuthProvider>
          <AuthConsumer>
            {(auth) => (
              <SettingsProvider>
                <SettingsConsumer>
                  {(settings) => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    // if (!settings.isInitialized) {
                    //   return null;
                    // }

                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes,
                    });

                    // Prevent guards from redirecting
                    const showSlashScreen = !auth.isInitialized;

                    return (
                      <ThemeProvider theme={theme}>
                        <GlobalStyles styles={globalButtonStyles} />
                        <Helmet>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Helmet>
                        <RTL direction={settings.direction}>
                          <CssBaseline />
                          {showSlashScreen ? (
                            <SplashScreen />
                          ) : (
                            // wait till initialize to call these.
                            <EstateAppApiProvider>
                              {element}
                            </EstateAppApiProvider>
                          )}
                          <Toaster />
                        </RTL>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            )}
          </AuthConsumer>
        </AuthProvider>
      </WhiteLabelConfigProvider>
    </LocalizationProvider>
    // </ReduxProvider>
  );
};
