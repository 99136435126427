import type { FC } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

interface TopAdminProps {
  onMobileNavOpen?: () => void;
  admin: string | null;
}

export const TopAdmin: FC<TopAdminProps> = (props) => {
  const { admin } = props;

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: "fixed",

        top: 0,
        width: {
          md: `100%`,
        },
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#10B981",
          py: 0.7,
          color: "primary.contrastText",
          height: "48px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth={"lg"} sx={{ height: "100%" }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems={"center"}
            textAlign={"left"}
            justifyContent={"center"}
            sx={{ height: "100%" }}
          >
            <Typography variant="button" sx={{ textTransform: "none" }}>
              {`You are viewing and contributing to ${admin}'s plan.`}
            </Typography>

            <Button
              endIcon={<EastOutlinedIcon />}
              variant="text"
              color="inherit"
              sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
            />
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

TopAdmin.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
