import { useReducer, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import {
  useV3Create,
  useV3Update,
  GenericPath,
  useV3GenericGetData,
} from "src/api/snugtotal/estate_app";

import { OtherUserDataObj } from "src/types/snugtotal";
import { useMounted } from "../use-mounted";

// Action Types
type OtherUserDataStoreAction =
  | { type: "SET_OTHER_USER_DATA"; payload: OtherUserDataObj }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "ADD_OR_UPDATE_OTHER_USER_DATA"; payload: OtherUserDataObj }
  | { type: "SET_OTHER_USER_DATA_SAVING_NEW"; payload: boolean };

// Reducer Function
const otherUserDataStoreReducer = (
  state: OtherUserDataStoreState,
  action: OtherUserDataStoreAction
): OtherUserDataStoreState => {
  switch (action.type) {
    case "SET_OTHER_USER_DATA":
      return {
        ...state,
        otherUserData: action.payload,
        otherUserDataLoading: false,
      };

    case "SET_LOADING":
      return { ...state, otherUserDataLoading: action.payload };
    case "ADD_OR_UPDATE_OTHER_USER_DATA":
      return { ...state, otherUserData: action.payload };
    case "SET_OTHER_USER_DATA_SAVING_NEW":
      return { ...state, otherUserDataSavingNew: action.payload };
    default:
      return state;
  }
};

// Initial State
interface OtherUserDataStoreState {
  otherUserData: OtherUserDataObj;
  otherUserDataLoading: boolean;
  otherUserDataSavingNew: boolean;
}

// Hook
export const useOtherUserDataStore = (ud_id: string | null) => {
  
  const isMounted = useMounted();
  const [state, dispatch] = useReducer(otherUserDataStoreReducer, {
    otherUserData: { id: null },
    otherUserDataLoading: true,
    otherUserDataSavingNew: false,
  });

  const createOtherUserData = useV3Create<OtherUserDataObj>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );
  const updateOtherUserData = useV3Update<OtherUserDataObj>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const fetchOtherUserData = useV3GenericGetData<OtherUserDataObj>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );

  const fetchOtherUserDataItems = useCallback(
    async (ud_id: string) => {
      if (!ud_id) {
        return;
      }

      dispatch({ type: "SET_LOADING", payload: true });

      try {
        const response = await fetchOtherUserData({
          ud_id: ud_id, //bleh. REFACTOR IN v3
          entity: "user-data-optional",
        });

        if (isMounted()) {
          dispatch({ type: "SET_OTHER_USER_DATA", payload: response });
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    },
    [fetchOtherUserData, isMounted]
  );

  const handleAddOrUpdateOtherUserData = useCallback(
    async (
      otherUserDataItem: OtherUserDataObj
    ): Promise<OtherUserDataObj | null> => {
      if (!ud_id) {
        return Promise.reject("Missing ud_id");
      }

      try {
        const isUpdate = Boolean(otherUserDataItem.id);
        let response;

        if (isUpdate) {
          // Update logic
          response = await updateOtherUserData(
            {
              ud_id: ud_id,
              entity: "user-data-optional",
              entity_id: otherUserDataItem.id || undefined,
            },
            otherUserDataItem,
            true // Assuming you want to perform a partial update
          );
        } else {
          // Create logic
          response = await createOtherUserData(
            { ud_id: ud_id, entity: "user-data-optional" }, // Adjust according to your actual API parameter requirements
            otherUserDataItem
          );
        }
        dispatch({ type: "ADD_OR_UPDATE_OTHER_USER_DATA", payload: response });
        return response;
      } catch (error) {
        toast.error("Failed to save otherUserData item");
        console.error(error);
        return null;
      }
    },
    [ud_id, createOtherUserData, updateOtherUserData]
  );

  useEffect(() => {
    if (ud_id) {
      fetchOtherUserDataItems(ud_id); // Fetch all otherUserData items
    }
  }, [ fetchOtherUserDataItems, ud_id]);

  return {
    otherUserData: state.otherUserData,
    otherUserDataLoading: state.otherUserDataLoading,
    otherUserDataSavingNew: state.otherUserDataSavingNew,
    handleAddOrUpdateOtherUserData,
  };
};
