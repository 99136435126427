import type { FC } from "react";
import PropTypes from "prop-types";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";
import Menu from "@mui/material/Menu";
import MenuItem, { menuItemClasses } from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";

interface VideoDropDownMenuProps {
  anchorEl?: HTMLElement | null;
  onClose?: () => void;
  open?: boolean;
  handleRemoveVideo: (videoId: string) => void;
  recordingId: string | null;
}

export const VideoDropDownMenu: FC<VideoDropDownMenuProps> = (props) => {
  const {
    anchorEl,
    onClose,
    open = false,
    handleRemoveVideo,
    recordingId,
  } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      sx={{
        [`& .${menuItemClasses.root}`]: {
          fontSize: 14,
          "& svg": {
            mr: 1,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      
      <MenuItem
        sx={{ color: "error.main" }}
        onClick={() => handleRemoveVideo(recordingId || "")}
      >
        <SvgIcon fontSize="small">
          <Trash02Icon />
        </SvgIcon>
        Remove
      </MenuItem>
    </Menu>
  );
};

VideoDropDownMenu.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
